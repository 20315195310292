.container {
  margin-top: 20px;
}

.heading {
  padding: 20px 10px 20px 0 ;
}

.App {
  text-align: center;
}


.container {
  position: relative;
  padding: 20px;
  width: 50%;
}

.container1 {
  position: relative;
  top: 50%;
  left: 33%;
}

.middle {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
text-align: center;
}

.middle1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  -ms-transform: translate(-50%, -50%); 
  text-align: center;
  }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

img {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
}

image {
  position: absolute;
  /* display: block; */
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  max-width: 100%;
  max-height: 100%;
}

svg{
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  max-width: 100%;
  max-height: 100%;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.u:hover {
  color: red;
}

.genericButton {
  text-align: center;
}

.App-link {
  color: #61dafb;
}



@media all and (min-width: 480px) {
  .feedback {
    padding: 0px 0;
  }

  .feedback form {
    margin: 0 auto;
    max-width: 320px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media all and (min-width: 480px) {
  .Login {
    padding: 15px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}